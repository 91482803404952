import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { FaTruck, FaHandshake } from "react-icons/fa";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { motion, useAnimation, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

const Stats = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  const { t } = useTranslation();
  return (
    <Container>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.6 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
      >
        <FaHandshake className="icon" />
        <h3>{t("stats1")}</h3>
      </motion.div>
      <hr />
      <motion.div
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.6 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.5, type: "spring" }}
      >
        <FaTruck className="icon" />
        <h3>{t("stats2")}</h3>
      </motion.div>
      <hr />
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.6 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.7, type: "spring" }}
      >
        <MdOutlineWorkspacePremium className="icon" />
        <h3>{t("stats3")}</h3>
      </motion.div>
    </Container>
  );
};
const Container = styled.div`
  padding: 5em 7% 2em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3em;
  z-index: 2;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    h3 {
      font-weight: 400;
      color: var(--fontSecondaryColor);
      text-align: center;
    }
    .icon {
      font-size: 4em;
      color: var(--fontPrimaryColor);
    }
  }
  hr {
    height: 100px;
  }
  @media (max-width: 650px) {
    gap: 1em;
  }
  @media (max-width: 500px) {
    gap: 1em;
    flex-direction: column;
    hr {
      width: 100%;
      height: 1px;
    }
  }
`;

export default Stats;
