import React, { useEffect } from "react";

const SmoothScroll = () => {
  useEffect(() => {
    const handleSmoothScroll = (event) => {
      if (event.target.nodeName === "A" && event.target.hash) {
        event.preventDefault();
        const targetId = event.target.hash.slice(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop,
            behavior: "smooth",
          });
        }
      }
    };

    document.addEventListener("click", handleSmoothScroll);

    return () => {
      document.removeEventListener("click", handleSmoothScroll);
    };
  }, []);

  return null;
};

export default SmoothScroll;
