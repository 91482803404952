import React from "react";
import styled from "styled-components";
import logoD from "../assets/logoFD.png";
import logoL from "../assets/logoFL.png";
import { FaPhoneAlt, FaFacebook, FaLinkedinIn, FaTiktok } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = ({ theme }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="top">
        {theme === "dark" ? (
          <img src={logoD} className="logo" alt="" />
        ) : (
          <img src={logoL} className="logo" alt="" />
        )}
        <div className="right">
          <div className="links">
            <h3>Links</h3>
            <Link to={"/"} className="link-styles">
              <p>Home</p>
            </Link>
            {/* <Link to={"/services"} className="link-styles">
              <p>Portfolio</p>
            </Link> */}
            <Link to={"/contact-us"} className="link-styles">
              <p>{t("contact")}</p>
            </Link>
          </div>
          <div className="contact">
            <div>
              <FaPhoneAlt className="icon" />
              <h3>+383 46 300 100</h3>
            </div>
            <div>
              <IoMail className="icon" />
              <h3>contact@steller-ks.com</h3>
            </div>
            <div>
              <Link to={"https://www.instagram.com/stellerks/"} target="_blank">
                <RiInstagramFill className="icon" />
              </Link>
              <Link to={"https://www.facebook.com/stellerks"} target="_blank">
                <FaFacebook className="icon" />
              </Link>
              <Link to={"https://www.tiktok.com/@stellerks"} target="_blank">
                <FaTiktok className="icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 3em 4%;
  background: var(--primaryBackgroundColor);
  box-shadow: 0 0 12px 1px var(--fontSecondaryColor);
  color: white;
  .top {
    border: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img {
      max-width: 340px;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4em;
      .links {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h3 {
          font-weight: 500;
          color: var(--fontPrimaryColor);
        }
        p {
          color: var(--fontSecondaryColor);
          font-weight: 300;
        }
      }
      .contact {
        display: flex;
        flex-direction: column;
        gap: 1em;
        div {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            font-size: 3em;
            padding: 10px;
            border-radius: 10px;
            color: var(--primaryBackgroundColor);
            background: var(--fontPrimaryColor);
          }
          h3 {
            font-weight: 300;
            color: var(--fontSecondaryColor);
          }
        }
        span {
          display: flex;
          gap: 10px;
          .icon {
            font-size: 3em;
            background: var(--primaryBackgroundColor);
            padding: 10px;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .bottom {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    p {
      color: var(--fontSecondaryColor);
      font-weight: 300;
      text-align: center;
    }
    img {
      width: 140px;
    }
  }
  @media (max-width: 1100px) {
    .top {
      flex-direction: column;
      gap: 3em;
      align-items: center;
      img {
        width: 80%;
      }
      .right {
        flex-wrap: wrap;
        div {
          flex-wrap: wrap;
        }
      }
    }
  }
`;

export default Footer;
