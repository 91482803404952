import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import useLocalStorage from "use-local-storage";
import Home from "./routes/Home";
import Footer from "./components/Footer";
import ContactPage from "./routes/ContactPage";
import Nav from "./components/homeComponents/Nav";
import Portfolio from "./routes/Portfolio";
import ReactGA from "react-ga";

ReactGA.initialize("G-FJPPFP79L5");

function App() {
  const [theme, setTheme] = useLocalStorage("theme" ? "light" : "dark");

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };
  return (
    <div className="App" data-theme={theme}>
      <Navbar theme={theme} switchTheme={switchTheme} />
      <Routes>
        <Route path="/" element={<Home theme={theme} />} />
        <Route path="/contact" element={<ContactPage theme={theme} />} />
        {/* <Route path="/portfolio" element={<Portfolio theme={theme} />} /> */}
      </Routes>
      <Footer theme={theme} />
    </div>
  );
}

export default App;
