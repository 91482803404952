import React from "react";
import styled from "styled-components";

const Portfolio = () => {
  return <Container>Portfolio</Container>;
};
const Container = styled.div`
  padding: 7em 7%;
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
`;

export default Portfolio;
