import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FaBars, FaTimes } from "react-icons/fa";

const Nav = () => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle(!toggle);
  const [activeTab, setActiveTab] = useState("home");
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (pathname === "/") {
      setActiveTab("home");
    } else if (pathname === "/produktet") {
      setActiveTab("produktet");
    } else if (pathname === "/projektet") {
      setActiveTab("projektet");
    } else if (pathname === "/partneret") {
      setActiveTab("partneret");
    } else if (pathname === "/rreth-nesh") {
      setActiveTab("rreth-nesh");
    } else if (pathname === "/kontakti") {
      setActiveTab("kontakti");
    }
  }, [pathname]);
  return (
    <Container>
      <ul
        onClick={handleToggle}
        className={toggle ? "nav-menu active" : "nav-menu"}
      >
        <Link to={"/produktet"} className="link-styles">
          <li className={activeTab === "produktet" ? "activeTab" : "nonActive"}>
            Produktet
          </li>
        </Link>
        <Link to={"/projektet"} className="link-styles">
          <li className={activeTab === "projektet" ? "activeTab" : "nonActive"}>
            Projektet
          </li>
        </Link>
        <Link to={"/partneret"} className="link-styles">
          <li className={activeTab === "partneret" ? "activeTab" : "nonActive"}>
            Partneret
          </li>
        </Link>
        <Link to={"/rreth-nesh"} className="link-styles">
          <li
            className={activeTab === "rreth-nesh" ? "activeTab" : "nonActive"}
          >
            Rreth nesh
          </li>
        </Link>
        <Link to={"/kontakti"} className="link-styles">
          <li className={activeTab === "kontakti" ? "activeTab" : "nonActive"}>
            Kontakti
          </li>
        </Link>
      </ul>
      <Link to={"/"} className="link-styles">
        MALESIA GROUP
      </Link>
      <div className="mobile-menu" onClick={handleToggle}>
        {toggle ? <FaTimes className="icon" /> : <FaBars className="icon" />}
      </div>
    </Container>
  );
};
const Container = styled.div`
  background: var(--trPrimaryBackgroundColor);
  transition: 0.4s;
  backdrop-filter: blur(7px);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 4%;
  width: 100%;
  height: 70px;
  z-index: 100;
  box-sizing: border-box;
  .nav-menu {
    display: flex;
    gap: 37px;
    list-style: none;
    align-items: center;
    li {
      color: #070707;
      text-transform: uppercase;
      font-weight: 400;
      transition: 0.3s;
      cursor: pointer;
      :hover {
        color: skyblue;
      }
    }
    .activeTab {
      text-decoration: underline;
      text-underline-offset: 7px;
      font-weight: 600;
    }
  }
  .mobile-menu {
    position: absolute;
    left: 5%;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s;
    display: none;
    margin-top: 7px;
    :hover {
      transform: scale(1.1);
    }
  }
  @media (max-width: 950px) {
    flex-direction: row-reverse;
    .nav-menu {
      width: 90%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      padding: 10%;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0%;
      right: -100%;
      background: #ffffffe1;
      transition: 0.3s;
      z-index: 99;
      border-left: 1px solid var(--fontSecondaryColor);
    }
    .mobile-menu {
      display: block;
      z-index: 99;
    }
    .nav-menu.active {
      right: 0%;
    }
  }
  @media (max-width: 400px) {
    .nav-menu {
      width: 98%;
    }
  }
`;

export default Nav;
