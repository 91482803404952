import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import b1 from "../../assets/b1.png";
import { motion, useAnimation, useInView } from "framer-motion";
import bgSvg from "../../assets/homeAssets/bgSvg.svg";

const Brands = ({ theme }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  return (
    <Container>
      <motion.h1
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.5 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.8, delay: 0.6, type: "spring" }}
      >
        KLIENTAT TONE
      </motion.h1>
      <Swiper
        className="swiper leftSwiper"
        // pagination={true}
        // navigation={{
        //   prevEl: ".swiper-button-prev",
        //   nextEl: ".swiper-button-next",
        // }}
        modules={[Pagination, Autoplay, Navigation]}
        slidesPerView={"auto"}
        spaceBetween={15}
        loop={true}
        navigation={true}
        autoplay={{
          delay: 900,
          disableOnInteraction: false,
        }}
        speed={1000}
      >
        <SwiperSlide className="slide">
          <img src={b1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={b1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={b1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={b1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={b1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={b1} alt="" />
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={b1} alt="" />
        </SwiperSlide>
      </Swiper>
      <img
        src={bgSvg}
        alt=""
        className={theme === "dark" ? "bgSvg dark" : "bgSvg"}
      />
    </Container>
  );
};
const Container = styled.div`
  padding: 2em 7%;
  position: relative;
  width: 100%;
  .bgSvg {
    position: absolute;
    width: 100%;
    max-width: 5000px;
    height: auto;
    filter: opacity(0.5);
    left: 0;
    top: 10%;
    z-index: 0;
  }
  .dark {
    filter: opacity(0.3);
  }
  h1 {
    text-align: center;
    font-size: 7vw;
    margin-bottom: 10px;
    font-weight: 200;
  }
  .swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    z-index: 2;
  }
  .slide {
    width: 250px;
    display: flex;
    justify-content: center;
  }
  img {
    z-index: 2;
    margin-bottom: 2em;
    height: 70px;
    width: 250px;
    max-width: 300px;
    object-fit: contain;
    opacity: 0.4;
    filter: grayscale(1);
  }
  .swiper-pagination {
    width: 100%;
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-pagination-bullet {
    width: 18px;
    height: 7px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 1px 1px gray;
    transition: background-color 0.3s ease;
  }
  .swiper-pagination-bullet-active {
    background-color: #d1d1d1; /* Change to your desired active color */
    opacity: 1;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: #e2e2e2;
    height: 12px;
  }
  @media (max-width: 500px) {
    .swiper {
      .slide {
        width: 100%;
      }
    }
  }
`;

export default Brands;
