import React from "react";
import styled from "styled-components";
import uniformsImg from "../../assets/homeAssets/uniforma.jpeg";
import { useTranslation } from "react-i18next";
import hoodiesVideo from "../../assets/homeAssets/hoodiesAnimation.mp4";
import shirtsVideo from "../../assets/homeAssets/shirtAnimation.mp4";
import jerseyVideo from "../../assets/homeAssets/jerseyAnimation.mp4";
import flagVideo from "../../assets/homeAssets/flagAnimation.mp4";

const ServicesComp = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="service">
        {/* <img src={hoddieImg} alt="" /> */}
        <video
          playsInline
          autoPlay
          loop
          muted
          preload="auto"
          type="video/mp4"
          src={hoodiesVideo}
        ></video>
        <div className="details">
          <h2>{t("hoddies")}</h2>
          <p className="desc">{t("hoddiesP")}</p>
          <div className="tags">
            <p>{t("hoodieK1")}</p>
            <p>{t("hoodieK2")}</p>
            <p>{t("hoodieK3")}</p>
          </div>
        </div>
      </div>
      <div className="service reversed">
        <video
          playsInline
          autoPlay
          loop
          muted
          preload="auto"
          type="video/mp4"
          src={shirtsVideo}
        ></video>
        <div className="details">
          <h2>{t("shirts")}</h2>
          <p className="desc">{t("shirtsP")}</p>
          <div className="tags">
            <p>{t("shirtsK1")}</p>
            <p>{t("shirtsK2")}</p>
            <p>{t("shirtsK3")}</p>
          </div>
        </div>
      </div>
      <div className="service">
        <video
          playsInline
          autoPlay
          loop
          muted
          preload="auto"
          type="video/mp4"
          src={jerseyVideo}
        ></video>
        <div className="details">
          <h2>{t("jerseys")}</h2>
          <p className="desc">{t("jerseysP")}</p>
          <div className="tags">
            <p>{t("jerseysK1")}</p>
            <p>{t("jerseysK2")}</p>
            <p>{t("jerseysK3")}</p>
          </div>
        </div>
      </div>
      <div className="service reversed">
        <video
          playsInline
          autoPlay
          loop
          muted
          preload="auto"
          type="video/mp4"
          src={flagVideo}
        ></video>
        <div className="details">
          <h2>Branding</h2>
          <p className="desc">{t("brandingP")}</p>
          <div className="tags">
            <p>{t("brandingK1")}</p>
            <p>{t("brandingK2")}</p>
            <p>{t("brandingK3")}</p>
          </div>
        </div>
      </div>
      <div className="service">
        <img src={uniformsImg} alt="" />
        <div className="details">
          <h2>{t("uniforms")}</h2>
          <p className="desc">{t("uniformsP")}</p>
          <div className="tags">
            <p>{t("uniformsK1")}</p>
            <p>{t("uniformsK2")}</p>
            <p>{t("uniformsK3")}</p>
          </div>
        </div>
      </div>
    </Container>
  );
};
const Container = styled.div`
  padding: 10em 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10em;
  .service {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    position: sticky;
    top: 12%;
    background: var(--trPrimaryBackgroundColor);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    img,
    video {
      width: 40%;
      max-height: 70vh;
      object-fit: cover;
      max-width: 700px;
      border-radius: 30px;
      margin: 2%;
    }
    .details {
      padding: 7% 3% 7% 2%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 1em;
      h2 {
        font-size: 3em;
        font-weight: 500;
      }
      .desc {
        color: var(--fontSecondaryColor);
        font-size: 18px;
        font-weight: 300;
        line-height: 1.6em;
      }
      .tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1em;
        p {
          background: var(--fontSecondaryColor);
          color: var(--primaryBackgroundColor);
          padding: 10px 15px;
        }
      }
    }
  }
  .reversed {
    flex-direction: row-reverse;
    .details {
      padding: 7% 2% 7% 4%;
      text-align: end;
      .tags {
        justify-content: end;
      }
    }
  }
  @media (max-width: 800px) {
    .service {
      flex-direction: column;
      video,
      img {
        width: 70%;
        max-height: 50vh;
      }
      .details {
        padding: 2% 3% 2% 2%;
        text-align: center;
        h2 {
          font-size: 1.5em;
        }
        .desc {
          line-height: 1em;
          font-size: 17px;
          height: 70px;
          overflow: scroll;
        }
        .tags {
          justify-content: center;
          p {
            font-size: 17px;
            padding: 8px 12px;
          }
        }
      }
    }
  }
`;

export default ServicesComp;
