import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import bgIcon from "../../assets/bgIcon2.png";
import { motion, useAnimation, useInView } from "framer-motion";
import logo from "../../assets/logoSL.png";
import logo2 from "../../assets/logoSD.png";
import { useTranslation } from "react-i18next";
import SmoothScroll from "../SmoothScrolling";

const Banner = ({ theme }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  return (
    <Container>
      <SmoothScroll />
      {theme === "dark" ? (
        <img src={logo2} alt="" className="logo" />
      ) : (
        <img src={logo} alt="" className="logo" />
      )}
      <motion.h1
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.6 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
      >
        {t("bannerTopH1")} <br />
        {t("bannerBottomH1")}
      </motion.h1>
      <motion.p
        variants={{
          hidden: { opacity: 0, y: 100 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.6, type: "spring" }}
      >
        {t("bannerP")}
      </motion.p>
      <motion.button
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
      >
        {t("bannerButton")}
      </motion.button>
      <img
        className={theme === "light" ? "" : "darkMode"}
        src={bgIcon}
        alt=""
      />
    </Container>
  );
};
const Container = styled.div`
  padding: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  position: relative;
  max-height: 700px;
  text-align: center;
  img {
    position: absolute;
    width: 46em;
    opacity: 0.7;
    transform: rotate(15deg);
    z-index: 0;
    top: 70%;
    height: 13em;
  }
  .logo {
    position: absolute;
    top: 12%;
    opacity: 0.07;
    transform: rotate(0);
  }
  .darkMode {
    opacity: 0.2;
  }
  span {
    font-size: 10vw;
    position: absolute;
    font-weight: 900;
    top: 10%;
    opacity: 1.07;
  }
  h1 {
    margin-top: 1em;
    z-index: 1;
    font-family: "Josefin Sans";
    font-size: 8vw;
  }
  p {
    z-index: 1;
    color: gray;
  }
  button {
    z-index: 1;
    margin-top: 1em;
    background: var(--fontPrimaryColor);
    color: var(--primaryBackgroundColor);
    font-size: 18px;
    font-weight: 300;
    font-family: "Montserrat", cursive;
    border: none;
    padding: 14px 20px;
    cursor: pointer;
    border-radius: 20px;
    transition: 0.4s;
    text-transform: uppercase;
    :hover {
      box-shadow: 0 0 12px 1px var(--fontSecondaryColor);
    }
  }
  @media (max-width: 950px) {
    span {
      top: 14%;
    }
    h1 {
      font-size: 10vw;
    }
    img {
      width: 89%;
      object-fit: contain;
    }
  }
  @media (max-width: 550px) {
    height: 70vh;
    padding: 7% 3%;
    .logo {
      top: 4%;
    }
    h1 {
      font-size: 15vw;
    }
    img {
      width: 89%;
    }
  }
`;

export default Banner;
