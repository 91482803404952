import styled from "styled-components";
import { motion, useTransform, useScroll } from "framer-motion";
import { useRef, useEffect, useState } from "react";
import step1Img from "../../assets/homeAssets/opImg1.jpeg";
import step2Img from "../../assets/homeAssets/opImg2.jpeg";
import step3Img from "../../assets/homeAssets/opImg3.jpeg";
import step4Img from "../../assets/homeAssets/opImg4.jpeg";
import step5Img from "../../assets/homeAssets/opImg6.jpeg";
import step6Img from "../../assets/homeAssets/opImg.jpeg";
import step7Img from "../../assets/homeAssets/opImg7.jpeg";
import step8Img from "../../assets/homeAssets/opImg8.jpeg";
import step9Img from "../../assets/homeAssets/opImg9.jpeg";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useTranslation } from "react-i18next";

const OurWork = () => {
  const { t } = useTranslation();
  const stepTitles = [
    t("firstStep"),
    t("secondStep"),
    t("thirdStep"),
    t("fourthStep"),
    t("fifthStep"),
    t("sixthStep"),
    t("seventhStep"),
    t("eightthStep"),
    t("ninethStep"),
  ];
  const stepDescs = [
    t("s1P"),
    t("s2P"),
    t("s3P"),
    t("s4P"),
    t("s5P"),
    t("s6P"),
    t("s7P"),
    t("s8P"),
    t("s9P"),
  ];
  return (
    <Container>
      <ScrollIndicator>
        <IndicatorText>{t("ourProcess")}</IndicatorText>
      </ScrollIndicator>
      <HorizontalScrollCarousel stepTitles={stepTitles} stepDescs={stepDescs} />
    </Container>
  );
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};
const HorizontalScrollCarousel = ({ stepTitles, stepDescs }) => {
  const targetRef = useRef(null);
  const isMobile = useIsMobile(); // Use the custom hook to check for mobile device

  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  // Adjust the transform percentage based on whether the device is mobile
  const endPercentage = isMobile
    ? `-${(cards.length - 1) * 112}%`
    : `-${(cards.length - 1) * 35}%`;

  const x = useTransform(scrollYProgress, [0, 1], ["0%", endPercentage]);

  return (
    <CarouselSection ref={targetRef}>
      <StickyContainer>
        <HorizontalContainer style={{ x }}>
          {cards.map((card, index) => (
            <>
              <Card
                card={card}
                title={stepTitles[index]}
                desc={stepDescs[index]}
                key={index}
              />
              <div>
                <MdOutlineKeyboardArrowRight className="icon" />
              </div>
            </>
          ))}
        </HorizontalContainer>
        <p className="scrollP">Keep Scrolling Down ↓</p>
      </StickyContainer>
    </CarouselSection>
  );
};

const Card = ({ card, title, desc }) => {
  return (
    <CardContainer>
      <CardImageContainer>
        <CardImage style={{ backgroundImage: `url(${card.url})` }} />
      </CardImageContainer>
      <CardOverlay>
        <CardTitle>{title}</CardTitle>
        <CardDesc>{desc}</CardDesc>
      </CardOverlay>
    </CardContainer>
  );
};

export default OurWork;

const cards = [
  {
    url: step1Img,
    desc: "Drawing the product (T-Shirt, Hoodie, Polo, anything we offer) on a peice of paper.",
    id: 1,
  },
  {
    url: step2Img,
    desc: "Designing the product using a Computer in order to print it later.",
    id: 2,
  },
  {
    url: step3Img,
    desc: "Heat Pressing. We print the desired materials and designs using the dynamic sublimation.",
    id: 3,
  },
  {
    url: step4Img,
    desc: "The product will be printed into different parts (arms, back etc.), so we need to sew the part together.",
    id: 4,
  },
  {
    url: step5Img,
    desc: "And last but not least; Quality Check, after a good quality check, the product will be packed and ready to go!",
    id: 5,
  },
  {
    url: step6Img,
    desc: "And last but not least; Quality Check, after a good quality check, the product will be packed and ready to go!",
    id: 6,
  },
  {
    url: step7Img,
    desc: "And last but not least; Quality Check, after a good quality check, the product will be packed and ready to go!",
    id: 7,
  },
  {
    url: step8Img,
    desc: "And last but not least; Quality Check, after a good quality check, the product will be packed and ready to go!",
    id: 8,
  },
  {
    url: step9Img,
    desc: "And last but not least; Quality Check, after a good quality check, the product will be packed and ready to go!",
    id: 9,
  },
];

// Styled Components
const Container = styled.div`
  background-color: var(--primaryBackgroundColor);
  margin-top: 5em;
`;
const ScrollIndicator = styled.div`
  display: flex;
  height: 12rem;
  align-items: center;
  justify-content: center;
`;

const IndicatorText = styled.span`
  font-weight: 600;
  text-transform: uppercase;
  color: var(--fontPrimaryColor);
  font-size: 7vw;
  font-weight: 200;
`;

const CarouselSection = styled.section`
  position: relative;
  height: 220vh;
  background-color: var(--primaryBackgroundColor);
`;

const StickyContainer = styled.div`
  position: sticky;
  top: 7em;
  height: auto;
  overflow: hidden;
  .scrollP {
    text-align: center;
    margin-top: 5%;
  }
`;

const HorizontalContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  .icon {
    font-size: 3em;
    color: var(--fontSecondaryColor);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const CardContainer = styled.div`
  position: relative;
  height: 37vw;
  width: 37vw;
  min-width: 200px; // Ensure cards don’t get too small on very narrow devices
  min-height: 200px;
  max-height: 90vh;
  flex-shrink: 0;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 4px;
  margin-right: 1rem;
  @media (max-width: 550px) {
    width: 90%;
    height: 60vh;
    margin-left: 2%;
  }
`;

const CardImageContainer = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;
  filter: brightness(0.7);
  overflow: hidden;
`;

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s;

  ${CardContainer}:hover & {
    transform: scale(1.1);
  }
`;

const CardOverlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
`;

const CardTitle = styled.p`
  background: #00000036;
  padding: 5px 20px;
  font-size: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  color: white;
  backdrop-filter: blur(4px);
  @media (max-width: 500px) {
    font-size: 0.8em;
  }
`;
const CardDesc = styled.p`
  background: #00000036;
  padding: 2px 10px 5px 20px;
  font-size: 1em;
  text-align: center;
  font-weight: 300;
  color: white;
  backdrop-filter: blur(4px);
  max-height: 20%;
  overflow: scroll;
  @media (max-width: 500px) {
    font-size: 0.7em;
    padding: 10px;
  }
`;
