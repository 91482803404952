import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook, FaTiktok } from "react-icons/fa";
import { motion, useAnimation, useInView } from "framer-motion";
import bgSvg from "../assets/homeAssets/bgSvg.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const ContactPage = ({ theme }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      emailjs
        .sendForm(
          "service_sxkklmq",
          "template_2z1mmkb",
          e.target,
          "Pgf7LMEWDtivUypt0"
        )
        .then((res) => {
          alert("Mesazhi u dergua me sukses!");
        })
        .catch((err) => console.log(err));
    } else {
      alert("Please fill in all fields.");
    }
  };
  return (
    <Container>
      <img src={bgSvg} alt="" className={theme === "dark" ? "dark" : ""} />
      <div className="left">
        <motion.h1
          ref={ref}
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -200 }}
          transition={{
            duration: 0.7,
            bounce: 0.3,
            delay: 0.4,
            type: "spring",
          }}
        >
          {t("contactH1")}
        </motion.h1>
        <motion.p
          animate={{ opacity: 1, x: 0 }}
          initial={{ opacity: 0, x: -200 }}
          transition={{
            duration: 0.7,
            bounce: 0.3,
            delay: 0.6,
            type: "spring",
          }}
        >
          {t("contactP")}
        </motion.p>
        <div>
          <motion.span
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 100 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 0.8,
              type: "spring",
            }}
          >
            <Link
              to={"https://www.instagram.com/stellerks/"}
              className="link-styles"
              target="_blank"
            >
              <RiInstagramFill className="icon" />
            </Link>
          </motion.span>
          <motion.span
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 100 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 1,
              type: "spring",
            }}
          >
            <Link
              to={"https://www.facebook.com/stellerks/"}
              className="link-styles"
              target="_blank"
            >
              <FaFacebook className="icon fbIcon" />
            </Link>
          </motion.span>
          <motion.span
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: 100 }}
            transition={{
              duration: 0.7,
              bounce: 0.3,
              delay: 1.2,
              type: "spring",
            }}
          >
            <Link
              to={"https://www.tiktok.com/@stellerks/"}
              className="link-styles"
              target="_blank"
            >
              <FaTiktok className="icon fbIcon" />
            </Link>
          </motion.span>
        </div>
      </div>
      <motion.form
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{
          duration: 0.7,
          delay: 0.8,
        }}
        onSubmit={sendEmail}
      >
        <h2>{t("contactUs")}</h2>
        <input
          type="text"
          name="name"
          placeholder={t("contactName")}
          required
        />
        <input
          type="phone"
          name="phoneNumber"
          placeholder={t("contactNumber")}
          required
        />
        <input type="email" name="email" placeholder="Email Addres" required />
        <textarea
          cols="20"
          rows="4"
          name="message"
          placeholder={t("contactMessage")}
          required
        ></textarea>
        <button type="submit">{t("contactSubmit")}</button>
      </motion.form>
    </Container>
  );
};
const Container = styled.div`
  color: var(--fontPrimaryColor);
  background: var(--primaryBackgroundColor);
  padding: 7em 8%;
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: "Montserrat", cursive;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    z-index: 0;
    opacity: 0.4;
    object-fit: cover;
  }
  .dark {
    opacity: 0.15;
  }
  .left {
    margin-top: 5%;
    width: 65%;
    z-index: 2;
    h1 {
      font-size: 5.7vw;
      line-height: 1.1em;
      font-weight: 400;
      span {
        color: var(--primaryColor);
      }
    }
    p {
      margin-right: 17%;
      color: var(--fontSecondaryColor);
      font-weight: 300;
      margin-top: 1em;
      max-width: 400px;
    }
    div {
      display: flex;
      align-items: center;
      gap: 1em;
      span {
        .icon {
          font-size: 3em;
          margin-top: 10px;
        }
        .fbIcon {
          font-size: 2.7em;
        }
      }
    }
  }
  form {
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 700px;
    padding: 2em;
    h2 {
      font-weight: 300;
      text-align: center;
      margin-bottom: 10px;
    }
    input,
    textarea {
      font-family: "Montserrat", cursive;
      padding: 10px;
      font-size: 16px;
      border: none;
      border-bottom: 1px solid var(--fontSecondaryColor);
      background: none;
      color: var(--fontPrimaryColor);
      outline: none;
      ::placeholder {
        color: var(--fontSecondaryColor);
      }
    }
    button {
      margin-top: 1em;
      display: flex;
      align-self: flex-start;
      margin: auto;
      background: var(--fontPrimaryColor);
      border: none;
      color: var(--primaryBackgroundColor);
      padding: 17px;
      font-size: 18px;
      font-family: "Comfortaa", cursive;
      cursor: pointer;
      transition: 0.4s;
      :hover {
        box-shadow: 0 0 14px 2px var(--shadowColor);
      }
    }
  }
  @media (max-width: 1300px) {
    padding: 4em 7%;
    .left {
      h1 {
        font-size: 3em;
      }
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    .left {
      width: 100%;
      h1 {
        font-size: 3em;
      }
      p {
        text-align: center;
        margin: auto;
      }
    }
    form {
      width: 90%;
    }
  }
  @media (max-width: 550px) {
    padding: 6em 6% 4em 6%;
    form {
      width: 100%;
      padding: 10px;
    }
  }
  @media (max-width: 550px) {
    .left {
      h1 {
        font-size: 2.4em;
      }
    }
  }
  @media (max-width: 410px) {
    .left {
      h1 {
        font-size: 2em;
      }
    }
  }
`;

export default ContactPage;
