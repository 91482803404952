import React, { useEffect } from "react";
import styled from "styled-components";
import Banner from "../components/homeComponents/Banner";
import Stats from "../components/homeComponents/Stats";
import Brands from "../components/homeComponents/Brands";
import OurWork from "../components/homeComponents/OurWork";
import ServicesComp from "../components/homeComponents/ServicesComp";
import ContactSection from "../components/homeComponents/ContactSection";

const Home = ({ theme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Banner theme={theme} />
      <Stats />
      {/* <Brands theme={theme} /> */}
      <OurWork />
      <ServicesComp />
      <ContactSection theme={theme} />
    </Container>
  );
};
const Container = styled.div`
  background: var(--primaryBackgroundColor);
  color: var(--fontPrimaryColor);
`;

export default Home;
