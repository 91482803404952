import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import alFlag from "../assets/albFlag.png";
import enFlag from "../assets/engFlag.svg";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const languageFlags = {
    al: alFlag,
    en: enFlag,
  };

  return (
    <Container>
      {Object.keys(languageFlags).map((language) => (
        <img
          key={language}
          src={languageFlags[language]}
          alt={language}
          className={`flag-icon ${language === i18n.language ? "active" : ""}`}
          onClick={() => changeLanguage(language)}
        />
      ))}
    </Container>
  );
};
const Container = styled.div`
  padding: 0 1%;
  display: flex;
  gap: 7px;
  img {
    width: 30px;
    height: auto;
    border: 10px;
  }
  .active {
    border: 2px solid blue;
  }
`;

export default LanguageSelector;
